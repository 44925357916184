import PageContents from '@/types/PageContents';
import ImageVideo from './joimax-Image-Video.html';

// const distanceX = 7.5;

const pageContent: PageContents[] = [
  {
    button: {
      top: '65.1%',
      left: '44.1%',
      content: 'Karlsruhe',
      type: 'open',
      textAlign: 'top',
      url: 'https://www.joimax.com/de/kontakt/',
    },
    modal: {
      content: '',
    },
  },
  {
    button: {
      top: '70%',
      left: '30.2%',
      content: 'Irvine',
      type: 'open',
      textAlign: 'left',
      url: 'https://www.joimax.com/us/directions-and-contact/',
    },
    modal: {
      content: '',
    },
  },
  {
    button: {
      top: '68%',
      left: '65.7%',
      content: 'Hongkong',
      url: 'https://www.joimax.com/en/directions-and-contact/',
      type: 'open',
    },
    modal: {
      content: '',
    },
  },
];

export const autoplayVideo = ImageVideo;
export default pageContent;
