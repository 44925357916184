




















import { Component, Vue } from 'vue-property-decorator';
import CircleButton from '@/components/CircleButton.vue';

import pageContent, { autoplayVideo } from '@/contents/joimax';

@Component({
  components: {
    CircleButton,
  },
})
export default class Education extends Vue {
  public modalOpen = '';

  public pageContent = pageContent;

  public autoplayVideo = autoplayVideo;

  openPage(link: string) {
    window.open(link, '_blank');
  }
}
